<template>
  <div class="building-filter">
    <SelectBuilding
      ref="filter"
      v-model="value"
      :items="items"
      :readonly="loadingBuilding"
      @populated="init"
      @input="reloadPage"
    />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Building Filter
 * ==================================================================================
 **/

import { mapState, mapMutations, mapGetters } from 'vuex'
import SelectBuilding from '@/components/fields/SelectBuilding'
import CookieMixin from '@/utils/mixins/Cookie'
import bus from '@/store/modules/bus'
import { find } from 'lodash'
// import ROLE from '@/utils/enums/Role'

export default {
  name: 'BuildingFilter',
  components: {
    SelectBuilding,
  },
  mixins: [CookieMixin],

  data() {
    return {
      value: null,
    }
  },

  computed: {
    ...mapState({
      buildingList: (state) => state.filter.buildingList,
      role: (state) => state.auth.role,
      loadingBuilding: (state) => state.filter.loadingBuilding,
    }),

    ...mapGetters({
      buildingStorage: 'filter/buildingStorage',
    }),

    items() {
      return this.role
        ? [
            {
              id: null,
              name: 'All',
            },
          ]
        : []
    },
  },

  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateBuilding()
      }
    },
    buildingList(newValue) {
      if (newValue && newValue.length > 0 && this.value !== null) {
        this.value = null
      }
    },
  },

  created() {
    bus.$on('buildingRemoved', this.onBuildingRemoved)
  },

  methods: {
    ...mapMutations({
      setBuildingId: 'filter/setBuildingId',
      setBuildingList: 'filter/setBuildingList',
      setLoading: 'filter/setLoading',
    }),

    init(buildings = []) {
      this.setBuildingList(buildings)
      this.setBuildingFilter(null)
      bus.$emit('buildingFilterLoaded')

      this.setLoading(false)
    },

    reset() {
      this.setCookie(this.buildingStorage, null)
    },

    updateBuilding() {
      this.setBuildingId(this.value)
      this.setCookie(this.buildingStorage, this.value)
    },

    onBuildingRemoved(building) {
      if (this.value === building.id) {
        this.setBuildingFilter(null)
      }
    },

    setBuildingFilter(value) {
      let realValue = value || null
      if (realValue) {
        const exist = this.isValid(realValue)
        realValue = exist ? realValue : null
      }

      this.value = realValue
      this.updateBuilding()
    },

    isValid(id = null) {
      const buildingId = id === null ? this.value : id
      return !!find(this.buildingList, { id: buildingId })
    },

    reloadPage(selectedValue) {
      // window.location.reload()
    },
  },
}
</script>
